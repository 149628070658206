// Lato

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-Light.ttf') format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-Bold.ttf') format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/montserrat/Montserrat-Bold.eot');
  src: url('../assets/fonts/montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
      url('../assets/fonts/montserrat/Montserrat-Bold.woff') format('woff'),
      url('../assets/fonts/montserrat/Montserrat-Bold.ttf') format('truetype'),
      url('../assets/fonts/montserrat/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/montserrat/Montserrat-Medium.eot');
  src: url('../assets/fonts/montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/montserrat/Montserrat-Medium.woff2') format('woff2'),
      url('../assets/fonts/montserrat/Montserrat-Medium.woff') format('woff'),
      url('../assets/fonts/montserrat/Montserrat-Medium.ttf') format('truetype'),
      url('../assets/fonts/montserrat/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/montserrat/Montserrat-Regular.eot');
  src: url('../assets/fonts/montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
      url('../assets/fonts/montserrat/Montserrat-Regular.woff') format('woff'),
      url('../assets/fonts/montserrat/Montserrat-Regular.ttf') format('truetype'),
      url('../assets/fonts/montserrat/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/montserrat/Montserrat-SemiBold.eot');
  src: url('../assets/fonts/montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2'),
      url('../assets/fonts/montserrat/Montserrat-SemiBold.woff') format('woff'),
      url('../assets/fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype'),
      url('../assets/fonts/montserrat/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/montserrat/Montserrat-Light.eot');
  src: url('../assets/fonts/montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/montserrat/Montserrat-Light.woff2') format('woff2'),
      url('../assets/fonts/montserrat/Montserrat-Light.woff') format('woff'),
      url('../assets/fonts/montserrat/Montserrat-Light.ttf') format('truetype'),
      url('../assets/fonts/montserrat/Montserrat-Light.svg#Montserrat-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/montserrat/Montserrat-ExtraBold.eot');
  src: url('../assets/fonts/montserrat/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
      url('../assets/fonts/montserrat/Montserrat-ExtraBold.woff') format('woff'),
      url('../assets/fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype'),
      url('../assets/fonts/montserrat/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/montserrat/Montserrat-Black.eot');
  src: url('../assets/fonts/montserrat/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/montserrat/Montserrat-Black.woff2') format('woff2'),
      url('../assets/fonts/montserrat/Montserrat-Black.woff') format('woff'),
      url('../assets/fonts/montserrat/Montserrat-Black.ttf') format('truetype'),
      url('../assets/fonts/montserrat/Montserrat-Black.svg#Montserrat-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}
