@import "bootstrap/scss/modal";
@import "variables";

// Modal

.modal-title {
  color: $white;
}

.modal-header {
  .close {
    color: $white;
  }
}

.modal-content {
  color: $white;
}

.app-menu-window {
  .modal-dialog {
    height: 100%;
    width: 80%;
  }

  .modal-content {
    height: 100%;
    background-color: transparent;
  }
}
.modal-backdrop.show{
  opacity: 0.5;
  z-index: auto !important;
}
