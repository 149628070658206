@font-face {
  font-family: 'fonticon';
  src:
    url('fonticon.ttf?q9ce77') format('truetype'),
    url('fonticon.woff?q9ce77') format('woff'),
    url('fonticon.svg?q9ce77#fonticon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ficon-"], [class*=" ficon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fonticon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ficon-delivery:before {
  content: "\e910";
}
.ficon-icn1:before {
  content: "\e957";
}
.ficon-icn2:before {
  content: "\e958";
}
.ficon-icn3:before {
  content: "\e959";
}
.ficon-plus:before {
  content: "\e900";
}
.ficon-angle-arrow-down:before {
  content: "\e901";
}
.ficon-calendar:before {
  content: "\e902";
}
.ficon-cat:before {
  content: "\e903";
}
.ficon-close:before {
  content: "\e904";
}
.ficon-check-thick:before {
  content: "\e905";
}
.ficon-dog:before {
  content: "\e906";
}
.ficon-heart-outline:before {
  content: "\e907";
}
.ficon-heart-solid:before {
  content: "\e908";
}
.ficon-info-circle:before {
  content: "\e909";
}
.ficon-bell:before {
  content: "\e90a";
}
.ficon-pawprint:before {
  content: "\e90b";
}
.ficon-cart:before {
  content: "\e90c";
}
.ficon-sort-arrows:before {
  content: "\e90d";
}
.ficon-star-outline:before {
  content: "\e90e";
}
.ficon-star-solid:before {
  content: "\e90f";
}
.ficon-substract:before {
  content: "\e956";
}
