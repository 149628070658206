/**
 * Use this file to override any styles from
 * bootstrap that you would like. Suggest creating
 * separate files for each overriding component
 * to match the bootstrap structure.
 */

// Bootstrap 'bootstrap/scss/bootstrap'


// src/styles/bootstrap/bootstrap.scss

// Import Bootstrap functions
@import "bootstrap/scss/functions";

// Import required Bootstrap variables
@import "bootstrap/scss/variables";

// Import Bootstrap mixins
@import "bootstrap/scss/mixins";

// Import Bootstrap root styles
@import "bootstrap/scss/root";

// Additonal Bootstrap form styles
@import "forms";

// Custom form styles
@import "custom_forms";

// Additonal Bootstrap modal styles
@import "modal";

// Additonal Bootstrap modal styles
@import "utilities";

// Import the rest of Bootstrap
@import "bootstrap/scss/bootstrap";

// Additonal spacing styles
@import "spacing";

// Additonal Bootstrap button styles
@import "buttons";


