
.app-logo {
  background: url("../assets/images/HillsToHomeLogo.svg") no-repeat 0 0;
  width: 100px;
  height: 100px;
  background-size: 100px 100px;
}

.app-logo-icon {
  background:url(../assets/images/HillsToHomeLogo.svg) no-repeat 0 0;
  width: 50px;
  height: 50px;
  background-size: 50px 50px;
}
