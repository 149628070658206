/**
 * Use this file to override any styles from
 * font-awesome that you would like. Suggest creating
 * separate files for each overriding component
 * to match the bootstrap structure.
 */

// Font Awesome '@fortawesome/fontawesome-free-webfonts/scss/fontawesome';

@import "variables";
@import '@fortawesome/fontawesome-free-webfonts/scss/mixins';
@import '@fortawesome/fontawesome-free-webfonts/scss/core';
@import '@fortawesome/fontawesome-free-webfonts/scss/larger';
@import '@fortawesome/fontawesome-free-webfonts/scss/fixed-width';
@import '@fortawesome/fontawesome-free-webfonts/scss/list';
@import '@fortawesome/fontawesome-free-webfonts/scss/bordered-pulled';
@import '@fortawesome/fontawesome-free-webfonts/scss/animated';
@import '@fortawesome/fontawesome-free-webfonts/scss/rotated-flipped';
@import '@fortawesome/fontawesome-free-webfonts/scss/stacked';
@import '@fortawesome/fontawesome-free-webfonts/scss/icons';
@import '@fortawesome/fontawesome-free-webfonts/scss/screen-reader';

// Font Solid '@fortawesome/fontawesome-free-webfonts/scss/fa-solid';

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  src: url('#{$fa-font-path}/fa-solid-900.eot');
  src: url('#{$fa-font-path}/fa-solid-900.eot?#iefix') format('embedded-opentype'),
  url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'),
  url('#{$fa-font-path}/fa-solid-900.woff') format('woff'),
  url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype'),
  url('#{$fa-font-path}/fa-solid-900.svg#fontawesome') format('svg');
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

// Font Regular '@fortawesome/fontawesome-free-webfonts/scss/fa-regular';

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  src: url('#{$fa-font-path}/fa-regular-400.eot');
  src: url('#{$fa-font-path}/fa-regular-400.eot?#iefix') format('embedded-opentype'),
  url('#{$fa-font-path}/fa-regular-400.woff2') format('woff2'),
  url('#{$fa-font-path}/fa-regular-400.woff') format('woff'),
  url('#{$fa-font-path}/fa-regular-400.ttf') format('truetype'),
  url('#{$fa-font-path}/fa-regular-400.svg#fontawesome') format('svg');
}

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

// Font Brands '@fortawesome/fontawesome-free-webfonts/scss/fa-brands';

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url('#{$fa-font-path}/fa-brands-400.eot');
  src: url('#{$fa-font-path}/fa-brands-400.eot?#iefix') format('embedded-opentype'),
  url('#{$fa-font-path}/fa-brands-400.woff2') format('woff2'),
  url('#{$fa-font-path}/fa-brands-400.woff') format('woff'),
  url('#{$fa-font-path}/fa-brands-400.ttf') format('truetype'),
  url('#{$fa-font-path}/fa-brands-400.svg#fontawesome') format('svg');
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}
