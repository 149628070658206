// src/styles/styles.scss

// Import Bootstrap functions
@import "../../../node_modules/bootstrap/scss/functions";

// Define custom theme colors
$app-gray-000: #fafafa;
$app-gray-100: #dadada;
$app-gray-200: #4b4b4b;
$app-gray-300: #4a4a4a;

$app-blue-000: #f3faff;
$app-blue-100: #4ba4eb;
$app-blue-200: #064479;
$app-blue-300: #003b70;
$app-blue-400: #113360;

// Color system
$primary: $app-blue-200;
$secondary: #fff;

// Spacing
$sizes: (
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  35: 35%,
  40: 40%,
  45: 45%,
  50: 50%,
  55: 55%,
  60: 60%,
  65: 65%,
  70: 70%,
  75: 75%,
  80: 80%,
  85: 85%,
  90: 90%,
  95: 95%,
  100: 100%,
  auto: auto,
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
);

// Links
$link-hover-decoration: none;

// Body
$body-color: $app-gray-200;

// Components
$border-color: $app-gray-100;
$border-radius-lg: 0.2rem;

// Fonts
$font-size-base: 0.875rem;
$font-family-sans-serif: "Montserrat", "Lato", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";

$headings-color: $app-gray-200;
$headings-line-height: 1;
$headings-font-family: "Montserrat", "Lato", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";
$headings-font-weight: 700;

// Forms
$input-placeholder-color: $app-gray-100;
$custom-control-indicator-checked-bg: $app-blue-100;

// Form validation
$form-feedback-margin-top: 0.75rem;

// Dropdowns
$dropdown-border-radius: 0;

// Navs
$nav-link-padding-y: 1rem;
$nav-tabs-border-radius: 0;
$nav-tabs-link-active-color: $app-gray-200;

// Navbar
$navbar-padding-y: 0.75rem;
$navbar-padding-x: 0;
$navbar-light-toggler-border-color: transparent;

// App Navbar
$app-navbar-nabvar-nav-top: 55px;

// Buttons + Forms
$btn-padding-y: 0.75rem;
$btn-padding-y-lg: 0.75rem;

$input-btn-padding-x: 0.75rem;
$input-btn-padding-x-lg: 1rem;

$input-border-width: 0;
$input-focus-width: 0;
$input-focus-box-shadow: none;

$custom-select-border-width: 0;

// Container
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  sm: 720px,
  md: 930px,
  lg: 960px,
  xl: 1140px,
  xxl: 1370px,
  xxxl: 1560px,
);

// Pagination
$pagination-padding-y: 1rem;

// Cards
$card-bg: transparent;
$card-cap-bg: transparent;
$card-border-radius: 0;
$card-spacer-y: 0;
$card-spacer-x: 0;

// Modals
$modal-dialog-margin: 0;
$modal-content-border-radius: 0;
$modal-content-border-width: 0;
$modal-content-bg: $app-blue-100;
$modal-header-border-width: 1px;
$modal-header-border-color: lighten($app-blue-100, 15%);
$modal-footer-border-width: 1px;

// Alerts
$alert-border-radius: 0;

// Close
$close-text-shadow: none;
$close-font-weight: 400;

// Import Bootstrap variables
@import "bootstrap/scss/variables";

// Other vars
$app-logo-width: 50px;
$app-zindex-loader-backdrop: 1080;
$app-zindex-loader: 1090;
$app-sml-transition-duration: 0.15s;
$app-med-transition-duration: 0.25s;
$app-lrg-transition-duration: 0.5s;
$app-transition-timing-function: linear;

// Define custom theme colors
$theme-colors: (
  "primary": #007bff,
  "secondary": #6c757d,
  "success": #28a745,
  "info": #17a2b8,
  "warning": #ffc107,
  "danger": #dc3545,
  "light": #f8f9fa,
  "dark": #343a40
);

// Define map-loop function
@function map-loop($map, $function, $args...) {
  $result: (
  );

@each $key, $value in $map {
  $result: map-merge($result, ($key: call(get-function($function), $value, $args...)));
}

@return $result;
}

// Convert theme colors to RGB
$theme-colors-rgb: map-loop($theme-colors, to-rgb);

// Define custom theme colors
$theme-colors-text: (
  primary: #007bff,
  secondary: #6c757d,
  // Add other colors
);

$theme-colors-bg-subtle: (
  light: #f8f9fa,
  dark: #343a40,
  // Add other colors
);

$theme-colors-border-subtle: (
  light: #f8f9fa,
  dark: #343a40,
  // Add other colors
);

$theme-colors-text-dark: (
  primary: black,
  secondary: gray,
  // Add other colors if needed
);

$theme-colors-bg-subtle-dark: (
  light: #e9ecef,
  dark: #212529,
  // Add other colors if needed
);

$theme-colors-border-subtle-dark: (
  light: #e9ecef,
  dark: #212529,
  // Add other colors if needed
);

// scss-docs-start theme-text-variables
$primary-text-emphasis: shade-color($primary, 60%) !default;
$secondary-text-emphasis: shade-color($secondary, 60%) !default;
$success-text-emphasis: shade-color($success, 60%) !default;
$info-text-emphasis: shade-color($info, 60%) !default;
$warning-text-emphasis: shade-color($warning, 60%) !default;
$danger-text-emphasis: shade-color($danger, 60%) !default;
$light-text-emphasis: $gray-700 !default;
$dark-text-emphasis: $gray-700 !default;
// scss-docs-end theme-text-variables

// scss-docs-start theme-bg-subtle-variables
$primary-bg-subtle: tint-color($primary, 80%) !default;
$secondary-bg-subtle: tint-color($secondary, 80%) !default;
$success-bg-subtle: tint-color($success, 80%) !default;
$info-bg-subtle: tint-color($info, 80%) !default;
$warning-bg-subtle: tint-color($warning, 80%) !default;
$danger-bg-subtle: tint-color($danger, 80%) !default;
$light-bg-subtle: mix($gray-100, $white) !default;
$dark-bg-subtle: $gray-400 !default;
// scss-docs-end theme-bg-subtle-variables

// scss-docs-start theme-border-subtle-variables
$primary-border-subtle: tint-color($primary, 60%) !default;
$secondary-border-subtle: tint-color($secondary, 60%) !default;
$success-border-subtle: tint-color($success, 60%) !default;
$info-border-subtle: tint-color($info, 60%) !default;
$warning-border-subtle: tint-color($warning, 60%) !default;
$danger-border-subtle: tint-color($danger, 60%) !default;
$light-border-subtle: $gray-200 !default;
$dark-border-subtle: $gray-500 !default;
// scss-docs-end theme-border-subtle-variables



/* Custom Properties */
:root {
  /* Warning Alert Custom Properties */
  --bs-warning-text-emphasis: #856404;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-warning-border-subtle: #ffeeba;

  /* Danger Alert Custom Properties */
  --bs-danger-text-emphasis: #721c24;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-danger-border-subtle: #f5c6cb;

  
}

/* Customize `alert-warning` with the custom properties */
.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

/* Customize `alert-danger` with the custom properties */
.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}