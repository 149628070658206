$custom-control-gutter: .5rem !default;
$custom-control-spacer-x: 1rem !default;
$custom-control-cursor: null !default;

$custom-control-indicator-size: 1rem !default;
$custom-control-indicator-bg: $input-bg !default;

$custom-control-indicator-bg-size: 50% 50% !default;
$custom-control-indicator-box-shadow: $input-box-shadow !default;
$custom-control-indicator-border-color: $gray-500 !default;
$custom-control-indicator-border-width: $input-border-width !default;

$custom-control-label-color: null !default;

$custom-control-indicator-disabled-bg: $input-disabled-bg !default;
$custom-control-label-disabled-color: $gray-600 !default;

$custom-control-indicator-checked-color: $component-active-color !default;
$custom-control-indicator-checked-bg: $component-active-bg !default;
$custom-control-indicator-checked-disabled-bg: theme-colors-rgb("primary", .5) !default;
$custom-control-indicator-checked-box-shadow: null !default;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg !default;

$custom-control-indicator-focus-box-shadow: $input-focus-box-shadow !default;
$custom-control-indicator-focus-border-color: $input-focus-border-color !default;

$custom-control-indicator-active-color: $component-active-color !default;
$custom-control-indicator-active-bg: lighten($component-active-bg, 35%) !default;
$custom-control-indicator-active-box-shadow: null !default;
$custom-control-indicator-active-border-color: $custom-control-indicator-active-bg !default;

$custom-checkbox-indicator-border-radius: $border-radius !default;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>") !default;

$custom-checkbox-indicator-indeterminate-bg: $component-active-bg !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>") !default;
$custom-checkbox-indicator-indeterminate-box-shadow: null !default;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg !default;

$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>") !default;

$custom-select-padding-y:           $input-padding-y !default;
$custom-select-padding-x:           $input-padding-x !default;
$custom-select-font-family:         $input-font-family !default;
$custom-select-font-size:           $input-font-size !default;
$custom-select-height:              $input-height !default;
$custom-select-indicator-padding:   1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight:         $input-font-weight !default;
$custom-select-line-height:         $input-line-height !default;
$custom-select-color:               $input-color !default;
$custom-select-disabled-color:      $gray-600 !default;
$custom-select-bg:                  $input-bg !default;
$custom-select-disabled-bg:         $gray-200 !default;
$custom-select-bg-size:             8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color:     $gray-800 !default;
$custom-select-indicator:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") !default;
$custom-select-background:          escape-svg($custom-select-indicator) right $custom-select-padding-x center / $custom-select-bg-size no-repeat !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right: add(1em * .75, (2 * $custom-select-padding-y * .75) + $custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-position:      center right ($custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-size:          $input-height-inner-half $input-height-inner-half !default;

$custom-select-border-width:        $input-border-width !default;
$custom-select-border-color:        $input-border-color !default;
$custom-select-border-radius:       $border-radius !default;
$custom-select-box-shadow:          inset 0 1px 2px rgba($black, .075) !default;

$custom-select-focus-border-color:  $input-focus-border-color !default;
$custom-select-focus-width:         $input-focus-width !default;
$custom-select-focus-box-shadow:    0 0 0 $custom-select-focus-width $input-btn-focus-color !default;

$custom-select-padding-y-sm:        $input-padding-y-sm !default;
$custom-select-padding-x-sm:        $input-padding-x-sm !default;
$custom-select-font-size-sm:        $input-font-size-sm !default;
$custom-select-height-sm:           $input-height-sm !default;

$custom-select-padding-y-lg:        $input-padding-y-lg !default;
$custom-select-padding-x-lg:        $input-padding-x-lg !default;
$custom-select-font-size-lg:        $input-font-size-lg !default;
$custom-select-height-lg:           $input-height-lg !default;

$custom-select-height: $input-height !default;
$custom-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight: $input-font-weight !default;
$custom-select-line-height: $input-line-height !default;
$custom-select-color: $input-color !default;
$custom-select-disabled-color: $gray-600 !default;
$custom-select-bg: $input-bg !default;
$custom-select-disabled-bg: $gray-200 !default;
$custom-select-bg-size: 8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: $gray-800 !default;
$custom-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") !default;
$custom-select-background: escape-svg($custom-select-indicator) right $custom-select-padding-x center / $custom-select-bg-size no-repeat !default; 

$custom-select-feedback-icon-padding-right: add(1em * .75, (2 * $custom-select-padding-y * .75) + $custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-position: center right ($custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half !default;

$custom-select-border-width: $input-border-width !default;
$custom-select-border-color: $input-border-color !default;
$custom-select-border-radius: $border-radius !default;
$custom-select-box-shadow: inset 0 1px 2px rgba($black, .075) !default;

$custom-select-focus-border-color: $input-focus-border-color !default;
$custom-select-focus-width: $input-focus-width !default;
$custom-select-focus-box-shadow: 0 0 0 $custom-select-focus-width $input-btn-focus-color !default;

$custom-select-padding-y-sm: $input-padding-y-sm !default;
$custom-select-padding-x-sm: $input-padding-x-sm !default;
$custom-select-font-size-sm: $input-font-size-sm !default;
$custom-select-height-sm: $input-height-sm !default;

$custom-select-padding-y-lg: $input-padding-y-lg !default;
$custom-select-padding-x-lg: $input-padding-x-lg !default;
$custom-select-font-size-lg: $input-font-size-lg !default;
$custom-select-height-lg: $input-height-lg !default;

// Embedded icons from Open Iconic.
// Released under MIT and copyright 2014 Waybury.
// https://useiconic.com/open


// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: $font-size-base * $line-height-base;
  padding-left: $custom-control-gutter + $custom-control-indicator-size;
  print-color-adjust: exact; // Keep themed appearance for print
}

.custom-control-inline {
  display: inline-flex;
  margin-right: $custom-control-spacer-x;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1; // Put the input behind the label so it doesn't overlay text
  width: $custom-control-indicator-size;
  height: ($font-size-base * $line-height-base + $custom-control-indicator-size) * .5;
  opacity: 0;

  &:checked~.custom-control-label::before {
    color: $custom-control-indicator-checked-color;
    border-color: $custom-control-indicator-checked-border-color;
    @include gradient-bg($custom-control-indicator-checked-bg);
    @include box-shadow($custom-control-indicator-checked-box-shadow);
  }

  &:focus~.custom-control-label::before {

    // the mixin is not used here to make sure there is feedback
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $custom-control-indicator-focus-box-shadow;
    }

    @else {
      box-shadow: $custom-control-indicator-focus-box-shadow;
    }
  }

  &:focus:not(:checked)~.custom-control-label::before {
    border-color: $custom-control-indicator-focus-border-color;
  }

  &:not(:disabled):active~.custom-control-label::before {
    color: $custom-control-indicator-active-color;
    background-color: $custom-control-indicator-active-bg;
    border-color: $custom-control-indicator-active-border-color;
    @include box-shadow($custom-control-indicator-active-box-shadow);
  }

  // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~.custom-control-label {
      color: $custom-control-label-disabled-color;

      &::before {
        background-color: $custom-control-indicator-disabled-bg;
      }
    }
  }
}

// Custom control indicators
//
// Build the custom controls out of pseudo-elements.

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  color: $custom-control-label-color;
  vertical-align: top;
  cursor: $custom-control-cursor;

  // Background-color and (when enabled) gradient
  &::before {
    position: absolute;
    top: ($font-size-base * $line-height-base - $custom-control-indicator-size) * .5;
    left: -($custom-control-gutter + $custom-control-indicator-size);
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    pointer-events: none;
    content: "";
    background-color: $custom-control-indicator-bg;
    border: $custom-control-indicator-border-width solid $custom-control-indicator-border-color;
    @include box-shadow($custom-control-indicator-box-shadow);
  }

  // Foreground (icon)
  &::after {
    position: absolute;
    top: ($font-size-base * $line-height-base - $custom-control-indicator-size) * .5;
    left: -($custom-control-gutter + $custom-control-indicator-size);
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    content: "";
    background: 50% / #{$custom-control-indicator-bg-size} no-repeat;
  }
}


// Checkboxes
//
// Tweak just a few things for checkboxes.

.custom-checkbox {
  .custom-control-label::before {
    @include border-radius($custom-checkbox-indicator-border-radius);
  }

  .custom-control-input:checked~.custom-control-label {
    &::after {
      background-image: escape-svg($custom-checkbox-indicator-icon-checked);
    }
  }

  .custom-control-input:indeterminate~.custom-control-label {
    &::before {
      border-color: $custom-checkbox-indicator-indeterminate-border-color;
      @include gradient-bg($custom-checkbox-indicator-indeterminate-bg);
      @include box-shadow($custom-checkbox-indicator-indeterminate-box-shadow);
    }

    &::after {
      background-image: escape-svg($custom-checkbox-indicator-icon-indeterminate);
    }
  }

  .custom-control-input:disabled {
    &:checked~.custom-control-label::before {
      @include gradient-bg($custom-control-indicator-checked-disabled-bg);
    }

    &:indeterminate~.custom-control-label::before {
      @include gradient-bg($custom-control-indicator-checked-disabled-bg);
    }
  }
}

// Radios
//
// Tweak just a few things for radios.

.custom-radio {
  .custom-control-label::before {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: $custom-radio-indicator-border-radius;
  }

  .custom-control-input:checked~.custom-control-label {
    &::after {
      background-image: escape-svg($custom-radio-indicator-icon-checked);
    }
  }

  .custom-control-input:disabled {
    &:checked~.custom-control-label::before {
      @include gradient-bg($custom-control-indicator-checked-disabled-bg);
    }
  }
}

// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.
//

.custom-select {
  display: inline-block;
  width: 100%;
  height: $custom-select-height;
  padding: $custom-select-padding-y (
    $custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
  font-family: $custom-select-font-family;
  @include font-size($custom-select-font-size
);
font-weight: $custom-select-font-weight;
line-height: $custom-select-line-height;
color: $custom-select-color;
vertical-align: middle;
background: $custom-select-bg $custom-select-background;
border: $custom-select-border-width solid $custom-select-border-color;
@include border-radius($custom-select-border-radius, 0);
@include box-shadow($custom-select-box-shadow);
appearance: none;

&:focus {
  border-color: $custom-select-focus-border-color;
  outline: 0;

  @if $enable-shadows {
    @include box-shadow($custom-select-box-shadow, $custom-select-focus-box-shadow);
  }

  @else {
    // Avoid using mixin so we can pass custom focus shadow properly
    box-shadow: $custom-select-focus-box-shadow;
  }

  &::-ms-value {
    // For visual consistency with other platforms/browsers,
    // suppress the default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: $input-color;
    background-color: $input-bg;
  }
}

&[multiple],
&[size]:not([size="1"]) {
  height: auto;
  padding-right: $custom-select-padding-x;
  background-image: none;
}

&:disabled {
  color: $custom-select-disabled-color;
  background-color: $custom-select-disabled-bg;
}

// Hides the default caret in IE11
&::-ms-expand {
  display: none;
}

// Remove outline from select box in FF
&:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 $custom-select-color;
}
}

.custom-select-sm {
  height: $custom-select-height-sm;
  padding-top: $custom-select-padding-y-sm;
  padding-bottom: $custom-select-padding-y-sm;
  padding-left: $custom-select-padding-x-sm;
  @include font-size($custom-select-font-size-sm);
}

.custom-select-lg {
  height: $custom-select-height-lg;
  padding-top: $custom-select-padding-y-lg;
  padding-bottom: $custom-select-padding-y-lg;
  padding-left: $custom-select-padding-x-lg;
  @include font-size($custom-select-font-size-lg);
}

