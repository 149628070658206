@import "bootstrap/scss/forms";
@import "variables";

// Form groups

.form-group {
  background-color: #fff;
  border-radius: 0.25rem;
  border: 1px solid #dadada;;
  box-sizing: content-box;
  padding: 1px;
  min-height: 35px;
  margin-bottom: 1rem;

  > label {
    padding-left: $input-btn-padding-x;
    padding-right: $input-btn-padding-x;
    padding-top: $input-btn-padding-y;
    color: $gray-700;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  > .form-control {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.is-invalid {
    border-color: $form-feedback-invalid-color;
    border-width: 2px;
    padding: 0;
  }
}

.app-invalid-feedback {
  background-color: $app-blue-100;
  color: darken($white, 5%);
  padding: 0.5rem;
  position: relative;

  @mixin app-arrow() {
    display: block;
    position: absolute;
    width: $popover-arrow-width;
    height: $popover-arrow-height;
    margin: 0 $border-radius-lg;

    &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }

  .app-bottom-arrow {
    @include app-arrow();

    top: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
    left: calc(50% - (#{$popover-arrow-width}));

    &:after {
      border-width: 0 calc($popover-arrow-width / 2) $popover-arrow-height
        calc($popover-arrow-width / 2);
    }

    &:after {
      top: $popover-border-width;
      border-bottom-color: $app-blue-100;
    }
  }

  &.alternate {
    background-color: $white;
    color: $primary;

    .app-bottom-arrow {
      &:after {
        border-bottom-color: $white;
      }
    }
  }
}
