@import "bootstrap/scss/buttons";
@import "variables";

// Buttons



.btn-outline-primary {
  &:hover {
    color: $primary;
    background-color: transparent;
    border-color: $primary;
  }

  &.disabled,
  &:disabled {
    &.active {
      color: color-yiq($primary);
      background-color: $primary;
      border-color: $primary;
    }
  }

  &:not(:disabled):not(.disabled):active {
    color: $primary;
    background-color: transparent;
    border-color: $primary;
  }
}

.btn-primary {
  color: #fff;
  background-color: #064479;
  border-color: #064479;

  &:hover {
    color: #fff;
    background-color: #043055;
    border-color: #042948;
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: #064479;
    border-color: #064479;
  }

  &:focus,
  &.focus ,
  &:focus-visible{
    color: #fff;
    background-color: #043055;
    border-color: #042948;
    box-shadow: 0 0 0 0.2rem rgba(43, 96, 141, 0.5);
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #042948;
  border-color: #03223c;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-secondary {
  color: #212529;
  background-color: #fff;
  border-color: #fff;

  &:hover ,
  &:focus,
  &:focus-visible{
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
  }

  &.disabled,
  &:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf;
  }
}

.btn-secondary.app-outline {
  border-color: $primary;
}