$percent:'%';
@for $value from 1 through 1000 {
    .ml-#{$value} {
        margin-left:#{$value}px !important;
    }

    .mr-#{$value} {
        margin-right:#{$value}px !important;
    }

    .pr-#{$value} {
        padding-right:#{$value}px !important;
    }

    .pl-#{$value} {
        padding-left:#{$value}px !important;
    }
    .w-#{$value} {
        width:#{$value}#{$percent} !important;
    }
}
